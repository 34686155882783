import axios from "axios"
import { Link } from "gatsby"
import React, { useState } from "react"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { useForm } from "react-hook-form"
import { useLocation } from "@reach/router"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const location = useLocation()

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok && typeof window !== "undefined") {
      // Initialize dataLayer if it doesn't exist
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "form_submission",
        formType: "contact",
        formName: "contact_form",
        pagePath: location.pathname,
      })
    }
  }
  const handleOnSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })

    const formData = new FormData(form)
    formData.append("g-recaptcha-response", serverState["g-recaptcha-response"])
    formData.append("source", location.pathname)

    axios({
      method: "post",
      url: "https://getform.io/f/pbmqrxrb",
      data: formData,
    })
      .then((r) => {
        handleServerResponse(true)
      })
      .catch((r) => {
        handleServerResponse(false)
      })
  }

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i

  const onVerify = React.useCallback(
    (token) => {
      setServerState((prevState) => ({
        ...prevState,
        "g-recaptcha-response": token,
      }))
    },
    [setServerState]
  )

  return (
    <>
      <>
        {serverState.status && serverState.status.ok ? (
          <>
            <div className="md:max-w-[80%]">
              <h3 className="text-[21px] leading-[27.31px] mb-6 font-serif">
                Thank you for your enquiry
              </h3>
              <p>
                A member of the Aria team will be in touch shortly. We
                appreciate your patience.
              </p>
            </div>
          </>
        ) : (
          <>
            <form className="w-full" onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="grid gap-4 lg:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="text-[16px] leading-[28px] mb-2"
                  >
                    First name
                  </label>
                  <input
                    placeholder="Jane*"
                    id="firstName"
                    {...register("firstName", { required: true })}
                    className={`form-control rounded-none border border-[#919191] font-sans text-newsletter placeholder-[#a6a6a6] bg-white w-full p-4 focus:outline-none ${
                      errors.firstName ? "border-red-500" : ""
                    }`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="text-[16px] leading-[28px] mb-2"
                  >
                    Last name
                  </label>
                  <input
                    placeholder="Smith*"
                    id="lastName"
                    {...register("lastName", { required: true })}
                    className={`form-control rounded-none border border-[#919191] font-sans text-newsletter placeholder-[#a6a6a6] bg-white w-full p-4 focus:outline-none ${
                      errors.lastName ? "border-red-500" : ""
                    }`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-[16px] leading-[28px] mb-2"
                  >
                    Email
                  </label>
                  <input
                    placeholder="name@email.com*"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: emailRegex,
                    })}
                    className={`form-control rounded-none border border-[#919191] font-sans text-newsletter placeholder-[#a6a6a6] bg-white w-full p-4 focus:outline-none ${
                      errors.email ? "border-red-500" : ""
                    }`}
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="text-[16px] leading-[28px] mb-2"
                  >
                    Phone
                  </label>
                  <input
                    placeholder="XXXX XXX XXX*"
                    id="phone"
                    {...register("phone", { required: true })}
                    className={
                      "form-control rounded-none border border-[#919191] font-sans text-newsletter placeholder-[#a6a6a6] bg-white w-full p-4 focus:outline-none " +
                      (errors.phone ? "border-red-500" : "")
                    }
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="message"
                  className="text-[16px] leading-[28px] mb-2"
                >
                  Message
                </label>
                <textarea
                  placeholder="Message"
                  id="message"
                  className="form-control h-[144px] rounded-none border border-[#919191] font-sans text-newsletter placeholder-[#a6a6a6] bg-white w-full p-4 focus:outline-none"
                />
              </div>
              <div>
                <label className="inline-flex cursor-pointer text-[14px]/[24px] my-3">
                  <input type="hidden" name="subscribe" value="no" />
                  <input
                    type="checkbox"
                    name="subscribe"
                    {...register("subscribe", {
                      required: true,
                    })}
                    className={errors.email ? "border-red-500" : ""}
                    value="yes"
                  />
                  <span
                    className={`text-sm ${
                      errors.subscribe ? "text-red-500" : ""
                    }`}
                  >
                    I consent to receive updates about A.P.G Co.’s products and
                    services.
                  </span>
                </label>
              </div>
              <input type="hidden" name="_gotcha" className="!hidden" />
              <GoogleReCaptcha onVerify={onVerify} />

              <p className="text-grey-base terms mt-3 max-w-[380px]">
                By submitting your enquiry, you indicate that you have read
                our&nbsp;
                <Link
                  className="underline hover:text-grey-base hover:no-underline"
                  to="/privacy"
                >
                  privacy collection notice
                </Link>
                &nbsp;and acknowledge that your personal information will be
                handled in accordance with that notice and our&nbsp;
                <a
                  className="underline hover:text-grey-base hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  href="/Aria-Privacy-Policy.pdf"
                >
                  privacy policy
                </a>
              </p>

              <button
                className="bg-[#232323] lg:w-[174px] text-center text-white font-medium justify-center font-sans text-[14px] leading-[24px] w-full mt-[40px] p-4 rounded-[4px] relative transition-colors duration-200 ease-in-out flex cursor-pointer items-center"
                type="submit"
                disabled={serverState.submitting}
              >
                {serverState.submitting ? "Submitting" : "Enquire now"}
              </button>
            </form>
            {serverState.status && !serverState.status.ok && (
              <p className="font-serif text-lg text-red-500">
                We're having an issue submitting form
              </p>
            )}
          </>
        )}
      </>
    </>
  )
}

export default ContactForm
